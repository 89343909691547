import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-react-intl"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import OrganizationLink from "../components/OrganizationLink"
import Footer from "../components/Footer"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const IntroContainer = styled.div`
  margin: 60px 0 20px 0;
  .imageWrapper {
    margin: 0 auto;
    text-align: center;
    .gatsby-image-wrapper {
      max-width: 100%;
      border-radius: 30px;
      img {
        filter: drop-shadow(0px 4px 46px rgba(0, 0, 0, 0.15));
        border-radius: 30px;
      }
    }
  }
  .introWrapper {
    font-family: "Kanit", sans-serif;
    padding-right: 0;
    span {
      font-weight: 300;
      font-size: 14px;
      color: #7497b2;
    }
    h2 {
      font-weight: 400;
      font-size: 38px;
      color: #181818;
      margin-bottom: 5px;
    }
    h3 {
      font-weight: 400;
      font-size: 28px;
      color: #181818;
      margin-bottom: 15px;
    }
    .content {
      font-weight: 400;
      font-size: 18px;
      color: #181818;
      p {
        font-weight: 400;
        font-size: 18px;
        color: #181818;
      }
      ul {
        li {
          font-weight: 400;
          font-size: 18px;
          color: #181818;
        }
      }
    }
  }

  @media only screen and (min-width: 576px) {
    .imageWrapper {
      .gatsby-image-wrapper {
        max-width: 80%;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .introWrapper {
      padding-right: 75px;
    }
  }
  @media only screen and (min-width: 992px) {
    margin: 60px 0;
    .imageWrapper {
      margin: 0;
      text-align: left;
      .gatsby-image-wrapper {
        max-width: 100%;
      }
    }
    .introWrapper {
      padding-right: 45px;
    }
  }
  @media only screen and (min-width: 1200px) {
    .imageWrapper {
      .gatsby-image-wrapper {
        max-width: 95%;
      }
    }
    .introWrapper {
      padding-right: 50px;
    }
  }
`
const FullwidthContainer = styled.div`
  margin: 0;
  font-family: "Kanit", sans-serif;
  .gb-container {
    padding-right: 15px;
  }
  .title {
    font-weight: 400;
    font-size: 24px;
    color: #181818;
  }
  .content {
    font-weight: 400;
    font-size: 16px;
    color: #646464;
  }

  @media only screen and (min-width: 576px) {
    .gb-container {
      padding-right: 10%;
    }
  }
  @media only screen and (min-width: 992px) {
    margin: 60px 0 40px 0;
    .gb-container {
      padding-right: 15%;
    }
  }
`
const GalleryContainer = styled.div`
  margin: 50px 0;
  .slick-slider {
    padding-bottom: 50px;
    .slick-slide {
      padding: 0 10px;
    }
  }
  .slick-dots {
    bottom: 0;
    li {
      width: 12px;
      height: 12px;
      background: #ebebeb;
      border-radius: 100%;
      button {
        width: 12px;
        height: 12px;
        padding: 0;
        border-radius: 100%;
        border: 2px solid #ebebeb;
        &:before {
          width: 12px;
          height: 12px;
          color: #ebebeb;
          background: #ebebeb;
          border-radius: 100%;
        }
      }
      &:hover {
        button {
          border: 2px solid #fff;
        }
      }
      &.slick-active {
        button {
          border: 2px solid #7497b2;
          &:before {
            color: #fff;
            background: #fff;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 992px) {
    margin: 70px 0;
    .slick-slider {
      padding-bottom: 70px;
    }
    .slick-dots {
      li {
        width: 15px;
        height: 15px;
        button {
          width: 15px;
          height: 15px;
          border: 3px solid #ebebeb;
          &:before {
            width: 15px;
            height: 15px;
          }
        }
        &:hover {
          button {
            border: 3px solid #fff;
          }
        }
        &.slick-active {
          button {
            border: 3px solid #7497b2;
          }
        }
      }
    }
  }
`

const AboutUsPage = ({ data, intl }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields } = data
  const {
    seoTitle,
    seoDescription,
    coverImage,
    pageTitle,
    introTitle,
    introText,
    introContent,
    introImage,
    fullwidthTitle,
    fullwidthContent,
    gallery,
  } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)
  const getIntroImage = getImage(introImage)

  const sliderSettings = {
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={intl.formatMessage({ id: "rootMenu3" })}
      />
      <IntroContainer>
        <div className="gb-container">
          <Row gutter={[80, 40]}>
            <Col className="imageWrapper" xs={24} lg={12}>
              <GatsbyImage image={getIntroImage} alt="" />
            </Col>
            <Col xs={24} lg={12}>
              <div className="introWrapper">
                <span>{pageTitle}</span>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: introTitle,
                  }}
                />
                <h3
                  dangerouslySetInnerHTML={{
                    __html: introText,
                  }}
                />
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: introContent.introContent,
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </IntroContainer>
      <FullwidthContainer>
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <h4
                className="title"
                dangerouslySetInnerHTML={{
                  __html: fullwidthTitle,
                }}
              />
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: fullwidthContent.fullwidthContent,
                }}
              />
            </Col>
          </Row>
        </div>
      </FullwidthContainer>
      <GalleryContainer>
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <div id="about__slider">
                <Slider {...sliderSettings}>
                  {gallery &&
                    gallery.map((slide, index) => {
                      const getSlideImage = getImage(slide)
                      return (
                        <div key={index} className="slide">
                          <GatsbyImage
                            image={getSlideImage}
                            alt={`About gallery ${index}`}
                          />
                        </div>
                      )
                    })}
                </Slider>
              </div>
            </Col>
          </Row>
        </div>
      </GalleryContainer>
      <OrganizationLink />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query AboutUsPageQuery($locale: String) {
    pageFields: allContentfulAboutPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          pageTitle
          introTitle
          introText
          introContent {
            introContent
          }
          introImage {
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          fullwidthTitle
          fullwidthContent {
            fullwidthContent
          }
          gallery {
            gatsbyImageData(width: 750, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default injectIntl(AboutUsPage)
